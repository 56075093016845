<template>
  <div class="test">
    <div id="map" class="GvolContainer" ref="map">
      <MapWeather :showId="showId"></MapWeather>
      <MapAllInfo
        :showId="showId"
        :cameraList="cameraList"
        :weatherCount="weatherCount"
      ></MapAllInfo>
      <MapEquipment
        :showId="showId"
        :online="infoWindowObj.online"
        :colDataList="mapColDataList[eqIndex]"
        :cameraList="cameraList"
        @changeSwitch="changeSwitch"
      ></MapEquipment>
      <MapCollection
        :colDataList="colDataList"
        :showId="showId"
      ></MapCollection>
      <MapLands :showId="showId" :plot_id="plotId"></MapLands>
      <MapCamera
        :showId="showId"
        :videoUrl="videoUrl"
        :cameraTitle="cameraTitle"
      ></MapCamera>
      <div class="popup-base">
        <div class="title">筛选条件</div>
        <div class="check-out-div">
          <el-checkbox-group class="checklist" v-model="groupTitle">
            <el-checkbox
              :label="item.group"
              v-for="(item, index) in cameraList"
              :key="index"
              @change="handleCheckedChange(item.group, index, $event)"
              >{{ item.group }}({{ item.count }})</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <div
      id="info-card"
      class="info-card win-box"
      :style="
        'background: url(' +
        $imgOnlineUrl +
        '/index/infoWin.png) center center no-repeat'
      "
    >
      <div class="title tops-box">
        <div class="title one-txt-cut">
          {{ infoWindowObj.title }}
        </div>
        <div class="status-box" :class="'active' + infoWindowObj.online">
          {{ infoWindowObj.online == 1 ? "在线" : "离线" }}
        </div>
      </div>
      <div class="bom-box">
        <p>
          {{ infoWindowObj.title }}&nbsp;&nbsp;设备状态：{{
            infoWindowObj.online == 1 ? "在线" : "离线"
          }}
        </p>
        <p>编号：{{ infoWindowObj.id }}</p>
      </div>
      <span class="top"></span>
    </div>
    <div id="valve-window">
      <div
        class="valve-window-item"
        v-for="(item, index) in mapColDataList"
        :key="index"
      >
        <div
          :class="['valve-window' + item2.id]"
          v-for="(item2, index2) in item"
          :key="index2"
        >
          <p class="one-txt-cut" style="color: #333">{{ item2.title }}</p>

          <el-switch
            style="width: 32px; transform: scale(0.8)"
            :disabled="online !== 1"
            v-if="item2.type === 1"
            :class="item2.last_data ? 'active' : ''"
            v-model="item2.last_data"
            @change="changeSwitch($event, item2.id, index)"
          >
          </el-switch>
        </div>
      </div>
    </div>
    <MapIntroCard></MapIntroCard>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import * as turf from "@turf/turf";

import {
  getAllMarkDataApi,
  getEquipmentChildListApi,
  postSetEquipmentChildListInfoApi,
  getEquipmentInfoApi,
} from "@/request/api";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style, Fill, Text, Stroke, Icon } from "ol/style";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Collection from "ol/Collection";
import Overlay from "ol/Overlay";
import * as Interaction from "ol/interaction";

export default {
  components: {
    MapWeather: () => import("@/components/MapWeather.vue"),
    MapAllInfo: () => import("@/components/MapAllInfo.vue"),
    MapLands: () => import("@/components/MapLands.vue"),
    MapCamera: () => import("@/components/MapCamera.vue"),
    MapCollection: () => import("@/components/MapCollection.vue"),
    MapEquipment: () => import("@/components/MapEquipment.vue"),
    MapIntroCard: () => import("@/components/MapIntroCard.vue"),
  },
  data() {
    return {
      cameraTitle: "",
      defaultImgs: this.$imgOnlineUrl + "/configure/farm/icon8.png",
      groupTitle: [],
      cameraList: [],

      plotForm: {},
      marker: null,
      overLayer: null,
      valveWindowLayer: null,
      infoBox: null,
      showId: "",
      contId: "",
      eqIndex: 0,
      infoWindowObj: {
        title: "",
        status: "",
      },
      iconMarkLayer: null,
      infoTitle: "",
      weatherObj: {},
      titleLayer: {},
      polygonLayer: null,
      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
      polygonsData: [],
      videoUrl: undefined,
      colDataList: [],
      mapColDataList: [],
      tableIdList: [],
      table_id: "",
      child_id: "",
      online: 1,
      data_value: "",
      data_type: "",
      weatherCount: "",
      olMap: {},
      titleFeatures: [],
      allDataList: {},
      vsource: new VectorSource({}),
      centerVal: 0,
      plotId: 0,
      flag: true,
      setTime: null,
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      userInfo: (state) => state.user.userInfo,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getAllMarkData();
  },
  methods: {
    ...mapMutations("app", ["SET_CAMERALIST"]),

    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");
      console.log(position);

      this.iconMarkLayer = new VectorLayer({
        zIndex: 3,
        source: this.vsource,
      });
      window.olMap = new Map({
        target: "map",
        layers: [
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer,
          this.titleLayer,
          this.iconMarkLayer,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: this.farmInfo.zoom,
          maxZoom: 18,
          constrainResolution: false, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      });
      this.infoBox = document.getElementById("info-card");
      this.overLayer = new Overlay({
        element: this.infoBox,
        zIndex: 4,
        positioning: "center-center",
        offset: [0, -15],
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
      this.hoverPoint();
      this.clickPoint();
      // this.allDataList["水肥一体"].forEach((item, index) => {
      //   this.getEquipmentChildListApi(item.id, index);
      // });
    },
    // 清除多边形
    clearDraw() {
      this.myPolygonSource.clear();
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 4,
          color: y,
        }),
      });
      return style;
    },
    showPolygon() {
      this.clearDraw();
      let array = this.polygonsData;
      let features = [];
      for (let i = 0; i < array.length; i++) {
        let coord = array[i].coord;
        let x = array[i].color[0];
        let y = array[i].color[1];
        let z = array[i].name;
        let f = new Feature({
          geometry: new Polygon([coord]),
          type: "isPolygon",
          id: array[i].id,
          title: array[i].title,
        });
        f.setStyle(this.featureStyle(x, y));
        f.setProperties({
          name: z,
        });
        features.push(f);
      }

      this.myPolygonSource.addFeatures(features);

      this.polygonLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 8,
        source: this.myPolygonSource,
        style: new Style({
          fill: new Fill({
            color: "",
          }),
          stroke: new Stroke({
            width: 4,
            color: "",
          }),
        }),
      });
    },
    // 点层
    cityPoint(list) {
      let features = [];
      let data = list;
      for (let i = 0; i < data.length; i++) {
        let feature = new Feature({
          geometry: new Point(data[i].position),
          type: "isPoint",
          citydata: data[i],
          title: data[i].title,
        });
        feature.setStyle(this.pointStyle(data[i].online, data[i].icon));
        features.push(feature);
      }
      this.vsource.addFeatures(features);
    },

    pointStyle(online, iconType) {
      let imgUrl = iconType + online + ".png";
      let src = this.$imgOnlineUrl + "/index/" + imgUrl;
      let Styles = [];
      Styles.push(
        new Style({
          // 设置图标
          image: new Icon({
            src,
            anchor: [0.5, 0.5],
            scale: 1,
          }),
        })
      );
      return Styles;
    },
    // 眼
    hoverPoint() {
      window.olMap.on("pointermove", (e) => {
        if (e.dragging) return;
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          let cityInfo = feature.get("citydata");
          let items = {};
          if (!cityInfo) return;

          switch (cityInfo.icon) {
            case "video":
              items = this.cameraList.find(
                (item) => item.group == "视频监控"
              ).items;
              break;
            case "grow":
              items = this.cameraList.find(
                (item) => item.group == "苗情监测"
              ).items;
              break;
            case "weather":
              items = this.cameraList.find(
                (item) => item.group == "气象采集"
              ).items;
              break;
            case "control":
              items = this.cameraList.find(
                (item) => item.group == "大棚控制"
              ).items;
              break;
            case "water":
              items = this.cameraList.find(
                (item) => item.group == "水质检测"
              ).items;
              break;
            case "soil":
              items = this.cameraList.find(
                (item) => item.group == "土壤检测"
              ).items;
              break;
            case "fertilizer":
              items = this.cameraList.find(
                (item) => item.group == "水肥一体"
              ).items;
              break;
            case "heat":
              items = this.cameraList.find(
                (item) => item.group == "加热机组"
              ).items;
              break;
            case "machine":
              items = this.cameraList.find(
                (item) => item.group == "农机监测"
              ).items;
              break;
            case "gate":
              items = this.cameraList.find(
                (item) => item.group == "阀门控制"
              ).items;
              break;
            case "insect":
              items = this.cameraList.find(
                (item) => item.group == "虫情监测"
              ).items;
              break;
            case "feed":
              items = this.cameraList.find(
                (item) => item.group == "智能投料"
              ).items;
              break;
            case "spore":
              items = this.cameraList.find(
                (item) => item.group == "孢子监测"
              ).items;
              break;
          }
          this.infoWindowObj = items.find((item) => item.id == cityInfo.id);
          this.overLayer.setPosition(cityInfo.position);

          if (this.flag) return;
          this.getEquipmentInfoApi_status_online("", cityInfo.id);
          // this.setTime = setTimeout(() => {
          //   this.flag = true;
          // }, 5000);
        } else {
          this.overLayer.setPosition(undefined);
        }
        window.olMap.addOverlay(this.overLayer);
      });
    },
    clickPoint() {
      window.olMap.on("singleclick", (e) => {
        console.log("点击的经纬度", e.coordinate);
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          if (feature.get("type") === "isPolygon") {
            this.plotId = feature.get("id");
            this.showId = "地块";

            // window.olMap.getView().animate({
            //   center: e.coordinate,
            //   zoom: 16,
            //   rotation: undefined,
            //   duration: 500,
            // });
          } else if (feature.get("type") === "isPoint") {
            this.contId = feature.values_.citydata.id;
            // this.eqIndex = this.tableIdList.findIndex(
            //   (item) => item == this.contId
            // );
            this.showId = feature.values_.citydata.icon;
            if (this.showId === "video" || this.showId === "grow") {
              this.videoUrl = `https://data.darsing.net/project/digital2/video/address?eid=${this.contId}&return_type=iframe`;
            } else {
              this.getEquipmentChildListApi(this.contId);
            }
            this.cameraTitle = this.infoWindowObj.title;
          } else {
            this.showId = "all";
          }
        }
      });
    },
    // 添加标题开始
    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "rgba(22,133,144,.3)",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 13,
        source: new VectorSource({
          features: new Collection(
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
          ),
          format: new GeoJSON(),
        }),
      });
    },
    // 选择类型
    handleCheckedChange(group, index, value) {
      if (value) {
        // 当勾选的是‘地块’时
        if (group === "地块") {
          // 更新多边形、文本标记
          this.showPolygon();
          this.addTitles();
        } else {
          this.cityPoint(this.allDataList[group]);
        }
      } else {
        // 当取消勾选的是‘地块’时
        if (group === "地块") {
          // 去除多边形
          this.clearDraw();
        } else {
          // 清除对应的点标记
          let clearArr = this.iconMarkLayer
            .getSource()
            .getFeatures()
            .filter((feature) => {
              return feature.values_.title === group;
            });
          clearArr.forEach((item) => {
            this.iconMarkLayer.getSource().removeFeature(item);
          });
        }
        this.$forceUpdate();
      }
    },
    // 获取设备类型
    async getAllMarkData() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;
      this.weatherCount = this.cameraList.findIndex(
        (item) => item.group === "气象采集"
      );
      this.cameraList.forEach((item) => {
        if (item.group === "虫情监测") {
          item.count >= 3 ? (item.count = item.count / 3) : "";
        }
        // 默认全选中
        this.groupTitle.push(item.group);
        if (item.group != "地块") {
          this.allDataList[item.group] = [];
          item.items.forEach((item2) => {
            let obj = {
              position: "",
              title: "",
              img: "",
              online: 1,
            };
            obj.title = item.group;
            obj.id = item2.id;
            obj.icon = item2.icon;
            obj.position = [
              +item2.mark_data.split(",")[0],
              +item2.mark_data.split(",")[1],
            ];
            obj.img = `${this.$imgOnlineUrl}/index/${item2.icon}${item2.online}.png`;
            this.allDataList[item.group].push(obj);
            this.$forceUpdate();
            // 图标默认全显示
            this.cityPoint(this.allDataList[item.group]);
          });
          this.$forceUpdate();
        } else {
          item.items.forEach((item2) => {
            // 处理坐标
            if (item2.mark_type == 3 && item2.mark_data) {
              let polygonsItemObj = { coord: [] };
              if (typeof item2.mark_data === "string") {
                item2.mark_data = JSON.parse(item2.mark_data);
              }
              item2.mark_data.forEach((item3) => {
                polygonsItemObj.coord.push([item3[0], item3[1]]);
              });
              polygonsItemObj.color = [item2.body_color, item2.border_color];
              polygonsItemObj.name = `polygon${item2.id}`;
              polygonsItemObj.id = item2.id;
              polygonsItemObj.title = item.group;
              let polygon = turf.polygon([item2.mark_data]);
              this.centerVal = turf.centroid(polygon).geometry.coordinates;
              this.polygonsData.push(polygonsItemObj);
              // 标题
              let titleMarkerObj = {
                text: item2.title,
                geometry: new Point(this.centerVal),
              };
              this.titleFeatures.push(titleMarkerObj);
            }
          });
          this.$forceUpdate();
        }
      });

      this.addTitles();
      this.showPolygon();
      setTimeout(() => {
        this.initMap();
      }, 80);
    },
    // 开关的切换
    changeSwitch(e, id) {
      // this.contId = this.tableIdList[index];
      // this.child_id = id;
      this.child_id = id;
      this.data_type = 1;
      this.data_value = e ? 1 : 0;
      this.postSetEquipmentChildListInfoApi();
    },
    // 设备详情 单独获取在线状态
    async getEquipmentInfoApi_status_online(title, table_id) {
      clearTimeout(this.setTime);
      this.flag = true;
      this.setTime = null;
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: table_id,
        act: "status",
      });
      if (code != 0) return;
      this.flag = false;

      if (results.online === undefined) {
        results.online = 0;
      }
      this.infoWindowObj.online = results.online;
    },
    async getEquipmentInfoApi_status(table_id, index = 0) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id: table_id,
        act: "status",
      });
      if (code === 0) {
        for (let key in this.colDataList) {
          let data1 = this.colDataList[key]?.number;
          for (let keys in results.data) {
            if (results.data[keys]?.number == data1) {
              this.colDataList[key].last_data = results.data[keys].last_data;
            }
          }
        }
        this.mapColDataList[index].forEach((k) => {
          let data1 = k?.number;
          for (let keys in results.data) {
            if (results.data[keys]?.number == data1) {
              k.last_data = results.data[keys].last_data;
            }
          }
          if (k.position) {
            let valveWindow = new Overlay({
              element: document.getElementsByClassName(
                `valve-window${k.id}`
              )[0],
              position: k.position.split(","),
              positioning: "center-center",
              offset: [0, -15],
              autoPan: false,
              autoPanAnimation: {
                duration: 250,
              },
            });
            window.olMap.addOverlay(valveWindow);
          }
        });

        this.$forceUpdate();
      }
    },
    // 设备节点设置
    async postSetEquipmentChildListInfoApi() {
      const { code, msg } = await postSetEquipmentChildListInfoApi({
        manor_id: this.farmID,
        table_id: this.contId,
        child_id: this.child_id,
        data_type: this.data_type,
        data_value: this.data_value,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已修改！",
          type: "success",
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    // 设备节点列表
    async getEquipmentChildListApi(table_id, index = 0) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
        page_size: 30,
      });
      if (code === 0) {
        this.colDataList = results;
        this.mapColDataList[index] = results;
        this.tableIdList[index] = table_id;
        this.getEquipmentInfoApi_status(table_id, index);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  width: 100%;
  height: 100%;
  background: #051218;
  /deep/.GvolContainer {
    width: 100vw !important;
    height: 100vh !important;
    position: relative;
    color: #fff;
  }
  .equipment,
  .collection,
  .weather {
    width: 282px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top: 6px solid #125397;
    padding: 23px 13px;
    background-color: #0f243d;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 106px;
    left: 78px;
    z-index: 1999;
    transition: all 0.5s;
    p {
      margin: 0 0 10px 0;
      &:first-of-type {
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 20px 0;
      }
    }
    .eq-title {
      color: #3e90e5;
      font-size: 16px;
      margin-bottom: 17px;
      text-align: center;
    }

    .eq-lists {
      display: flex;
      width: 100%;
      flex-direction: column;

      .eq-items {
        width: 100%;
        height: 41px;
        background: rgba(62, 144, 229, 0.25);
        border: 1px solid #3e90e5;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 6px 14px;
        justify-content: space-between;
        margin-bottom: 14px;

        .left-div {
          display: flex;
          align-items: center;
          .icon1-img {
            width: 30px;
            height: 30px;
            margin-right: 16px;
          }
        }
        .name,
        .val {
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
        /deep/.my-switch {
          transform: scale(0.8);
          .el-switch__core {
            width: 22px !important;
            background-color: #d8e5f0;
          }

          .el-switch__core:after {
            background-color: #a6aeb5;
          }
        }

        .my-switch.active {
          .el-switch__core:after {
            background-color: #2878ff;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }

  .popup-base {
    width: 300px;
    position: absolute;
    z-index: 1998;
    top: 86px;
    right: 80px;

    .title {
      width: 100%;
      height: 32px;
      font-size: 14px;
      padding: 8px 10px;
      box-sizing: border-box;
      background: rgba(21, 92, 165, 0.9);
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px 4px 0 0;

      div {
        flex: 1;
        span {
          background: #0e4680;
          border-radius: 10px;
          font-size: 8px;
          padding: 2px 15px;
          margin: 0 4px 0px 0;
        }
      }
    }
    .check-out-div {
      width: 100%;
      padding: 8px 16px;
      background-color: #0f243d;
      text-align: left;

      .checklist.el-checkbox-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        // justify-content: center;
        text-align: left;
        /deep/.el-checkbox {
          width: 50%;
          color: #fff;
          // margin-bottom: 10px !important;
          margin: 0 0 10px 0;
        }
        /deep/.el-checkbox__label {
          font-size: 14px;
        }
      }
    }

    a {
      color: #fff;
      padding: 8px 0;
      display: inline-block;
    }
  }
}
.win-box {
  .tops-box {
    width: 100%;
    height: 51px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 34px;
    display: flex;
    align-items: center;

    .title {
      color: #3e90e5;
      font-size: 16px;
      font-weight: bold;
      line-height: 40px;
      margin-right: 10px;
    }

    .status-box {
      width: 45px;
      height: 17px;
      border-radius: 17px;
      border: 1px solid red;
      line-height: 14px;
      text-align: center;
      color: red;
      font-size: 11px;
      // &.active0 {
      //   color: red !important;
      //   border-color: red !important;
      // }
      &.active1 {
        color: #08a600 !important;
        border-color: #08a600 !important;
      }
    }
  }

  .bom-box {
    width: 100%;
    height: calc(100% - 51px);
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      font-size: 14px;
      color: #fff;
      margin-bottom: 19px;
    }
  }
}

.info-card {
  width: 320px;
  height: 144px;
  position: absolute;
  bottom: 18px;
  left: -160px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);

  .title {
    height: 40px;
    color: #ffffff;

    span.title_name {
      position: relative;
      top: 7px;
      left: 10px;
      font-size: 18px;
    }
  }

  span.top {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    border-width: 10px;
    border-style: solid dashed dashed;
    border-color: #051627 transparent transparent;
    transform: translateX(-50%);
    left: 50%;
    bottom: -13px;
  }
}
</style>
